// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,

  firebase: {
    apiKey: "AIzaSyALPamTkVwuX5E_PWDvxnokhQM3gNbex54",
    authDomain: "smosify-f7e81.firebaseapp.com",
    databaseURL: "https://smosify-f7e81-default-rtdb.firebaseio.com",
    projectId: "smosify-f7e81",
    storageBucket: "smosify-f7e81.appspot.com",
    messagingSenderId: "788744391173",
    appId: "1:788744391173:web:2009d9c55f812fd99c6a6c",
    measurementId: "G-7RXBHFGFHM",
    vapidKey:
      "BPBHGVaPXpYvOw8_Olfe28F6rifgTwo_dBeICP06WZSv8_Q9A6uLCc16ES--W66US0LOCd7ovXk-F2325BGPPto",
  },

  // production: true,
  // //  apiUrl: "http://122.160.199.147:3000",
  // //apiUrl: "http://122.176.29.12:3000",
  // apiUrl: "https://api.watchfuleye.in:2053",
  // //crmUrl: "http://14.99.243.78",
  // crmUrl: "https://crm.watchfuleye.in",
  // bucketUrl: "https://healthapp.s3.us-east-2.amazonaws.com/staging/",
  // SOCKET_ENDPOINT: "https://api.watchfuleye.in:2053",

  //  apiUrl: "http://localhost:3000",
  //apiUrl: "https://api.watchfuleye.in:2053",
  apiUrl: "https://smosify.com:8443",
  //  crmUrl: "http://14.99.243.78",
  crmUrl: "https://crm.watchfuleye.in",
  bucketUrl: "https://salonza.s3.us-east-2.amazonaws.com/staging/",
  partnerapiUrl: "https://smosify.com:2053",

  // Old Razorpay Key
  // razorPay_Key: 'rzp_test_KtUsmVQXOB66sE',

  // New Razorpay Key
  razorPay_Key: "rzp_test_5oxkCULZWlxU8F",

  // apiUrl: "http://122.160.199.147:3000",
  //apiUrl: "https://api.watchfuleye.in:2053",
  //  crmUrl: "http://14.99.243.78",
  //crmUrl: "https://crm.watchfuleye.in",
  //bucketUrl: "https://healthapp.s3.us-east-2.amazonaws.com/staging/",
  SOCKET_ENDPOINT: "https://smosify.com:8443",
  PARTNER_SOCKET_ENDPOINT: "https://smosify.com:2053",
  paymentLink: "http://122.160.75.221/salonza/#/",
  siteKey: "6LdrukkfAAAAAOlaBD6W5uNWD-Pmh_Bq1xiLc77y",
  // idelTimeInSeconds: 840,
  idelTimeInSeconds: 28000,
  // idelTimeInSeconds: 120,

  timeOutInSeconds: 60,
  publicsocialurl: "https://staging.smosify.com",
  socialSuffix: "/partnerdetails",
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
