import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { Calendar } from "./calendar.model";
import { Observable } from "rxjs";
import { throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { NotificationService } from "src/app/ServicesNotification/notification.service";

@Injectable({
  providedIn: "root",
})
export class CalendarService {
  private readonly user_data =
    JSON.parse(localStorage.getItem("user")) ||
    JSON.parse(localStorage.getItem("id"));
  private readonly role = this.user_data.role;
  private messageSource = new BehaviorSubject("default message");
  currentMessage = this.messageSource.asObservable();
  private readonly API_URL = "assets/data/calendar.json";
  httpOptions = {
    headers: new HttpHeaders({ "Content-Type": "application/json" }),
  };
  dataChange: BehaviorSubject<Calendar[]> = new BehaviorSubject<Calendar[]>([]);

  // Temporarily stores data from dialogs
  dialogData: any;
  constructor(
    private httpClient: HttpClient,
    private notfication: NotificationService
  ) { }
  get data(): Calendar[] {
    return this.dataChange.value;
  }

  getDialogData() {
    return this.dialogData;
  }

  getAllCalendars(): Observable<Calendar[]> {
    return this.httpClient
      .get<Calendar[]>(this.API_URL)
      .pipe(catchError(this.errorHandler));
  }

  addUpdateCalendar(calendar: Calendar): void {
    this.dialogData = calendar;
  }

  deleteCalendar(calendar: Calendar): void {
    this.dialogData = calendar;
  }

  bookingRing(status) {
    if (status) {
      let audio = new Audio();
      if (status.statusId == 1) {
        audio.src = "../../../assets/audio/Confirmed.mp3"; // "../../../assets/audio/ambu.wav";
      } else if (status.statusId == 2) {
        audio.src = "../../../assets/audio/Paid.mp3"; // "../../../assets/audio/ambu.wav";
      } else if (status.statusId == 3) {
        audio.src = "../../../assets/audio/Cancelled.mp3"; // "../../../assets/audio/ambu.wav";
      }
      audio.load();
      audio.play();
    }
  }

  errorHandler(error) {
    let errorMessage = "";
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.log(errorMessage);
    return throwError(errorMessage);
  }

  // New Implementation
  getFilteredNumberPartner(mob) {
    let headers = new HttpHeaders({
      Authorization: "Bearer " + JSON.parse(localStorage.getItem("user")).token,
    });
    return this.httpClient.post(
      `${environment.apiUrl}/admin/searchPartnerFilterMobile`,
      { mobile: mob },
      { headers: headers }
    );
    // else{
    //   return this.http.post(`${environment.apiUrl}/staff/searchStaffPatientFilterMobile`,{mobile:mob},{headers:headers});
    // }
  }

  getFilteredNamePartner(mob) {
    let headers = new HttpHeaders({
      Authorization: "Bearer " + JSON.parse(localStorage.getItem("user")).token,
    });
    return this.httpClient.post(
      `${environment.apiUrl}/admin/searchPartnerFilterNameNew`,
      { name: mob },
      { headers: headers }
    );
  }

  getPartner(obj) {
    let headers = new HttpHeaders({
      Authorization: "Bearer " + JSON.parse(localStorage.getItem("user")).token,
      //'Content-type': 'multipart/form-data'
    });
    return this.httpClient.post(
      `${environment.apiUrl}/admin/searchPartnerByMobile`,
      { mobile: obj.mobile },
      { headers: headers }
    );
  }

  getSubServiceByService(data) {
    let headers = new HttpHeaders({
      Authorization: "Bearer " + JSON.parse(localStorage.getItem("user")).token,
    });
    return this.httpClient.post(
      `${environment.apiUrl}/service/getSubServicesByServiceId`,
      data,
      { headers: headers }
    );
  }

  // Default Calling, If Offer Not Exists.
  getService(v) {
    let headers = new HttpHeaders({
      Authorization: "Bearer " + JSON.parse(localStorage.getItem("user")).token,
    });
    return this.httpClient.post(
      `${environment.apiUrl}/admin/getServicesByPartnerId`,
      { partnerId: v },
      { headers: headers }
    );
  }

  // Calling If Offer Apply Only On Individual Or Top Offer Exists Services
  getServicesWithServiceOffer(v, id) {
    let headers = new HttpHeaders({
      Authorization: "Bearer " + JSON.parse(localStorage.getItem("user")).token,
    });
    return this.httpClient.post(
      `${environment.apiUrl}/partner/getServicesWithServiceOffer`,
      { partnerId: v, offerId: id },
      { headers: headers }
    );
  }

  // All Services Type Offer
  getServicesWithServiceOfferForAllServicesOffer(v, id) {
    let headers = new HttpHeaders({
      Authorization: "Bearer " + JSON.parse(localStorage.getItem("user")).token,
    });
    return this.httpClient.post(
      `${environment.apiUrl}/partner/getServicesListPartnerbyAllServiceOffer`,
      { partnerId: v, offerId: id },
      { headers: headers }
    );
  }

  getFilteredNumber(obj) {
    let headers = new HttpHeaders({
      Authorization: "Bearer " + JSON.parse(localStorage.getItem("user")).token,
    });
    if (this.role == "admin") {
      return this.httpClient.post(
        `${environment.apiUrl}/partner/searchClientFilterMobileNew`,
        { data: obj },
        { headers: headers }
      );
    } else if (this.role == "partner") {
      return this.httpClient.post(
        `${environment.partnerapiUrl}/partner/searchClientFilterMobileNew`,
        { data: obj },
        { headers: headers }
      );
    } else {
      return this.httpClient.post(
        `${environment.apiUrl}/partner/searchClientFilterMobile`,
        { data: obj },
        { headers: headers }
      );
    }
  }

  getFilteredName(mob) {
    let headers = new HttpHeaders({
      Authorization: "Bearer " + JSON.parse(localStorage.getItem("user")).token,
    });
    if (this.role == "admin") {
      return this.httpClient.post(
        `${environment.apiUrl}/partner/searchClientFilterNameNew`,
        { data: mob },
        { headers: headers }
      );
    } else {
      return this.httpClient.post(
        `${environment.apiUrl}/partner/searchClientFilterNameNew`,
        { data: mob },
        { headers: headers }
      );
    }
  }

  getVoucherDetails(obj) {
    let headers = new HttpHeaders({
      Authorization: "Bearer " + JSON.parse(localStorage.getItem("user")).token,
    });
    if (this.role == "admin") {
      return this.httpClient.post(
        `${environment.apiUrl}/admin/getVoucherDetails`,
        { data: obj },
        { headers: headers }
      );
    } else {
      return this.httpClient.post(
        `${environment.apiUrl}/admin/getVoucherDetails`,
        { data: obj },
        { headers: headers }
      );
    }
  }

  getMembershipDetails(obj) {
    let headers = new HttpHeaders({
      Authorization: "Bearer " + JSON.parse(localStorage.getItem("user")).token,
    });
    if (this.role == "admin") {
      return this.httpClient.post(
        `${environment.apiUrl}/admin/getMemberVoucherDetails`,
        { data: obj },
        { headers: headers }
      );
    } else {
      return this.httpClient.post(
        `${environment.apiUrl}/admin/getMemberVoucherDetails`,
        { data: obj },
        { headers: headers }
      );
    }
  }

  cancelEvents(obj) {
    let headers = new HttpHeaders({
      Authorization: "Bearer " + JSON.parse(localStorage.getItem("user")).token,
    });
    if (this.role == "admin") {
      return this.httpClient.post(
        `${environment.partnerapiUrl}/admin/cancelBooking`,
        obj,
        { headers: headers }
      );
    } else {
      return this.httpClient.post(
        `${environment.partnerapiUrl}/admin/cancelBooking`,
        obj,
        { headers: headers }
      );
    }
  }

  rescheduleBooking(obj) {
    let headers = new HttpHeaders({
      Authorization: "Bearer " + JSON.parse(localStorage.getItem("user")).token,
    });
    if (this.role == "admin") {
      return this.httpClient.post(
        `${environment.apiUrl}/admin/rescheduleBooking`,
        { data: obj },
        { headers: headers }
      );
    } else {
      return this.httpClient.post(
        `${environment.apiUrl}/admin/rescheduleBooking`,
        { data: obj },
        { headers: headers }
      );
    }
  }

  completeBooking(obj) {
    let headers = new HttpHeaders({
      Authorization: "Bearer " + JSON.parse(localStorage.getItem("user")).token,
    });
    if (this.role == "admin") {
      return this.httpClient.post(
        `${environment.apiUrl}/admin/completeBooking`,
        { id: obj },
        { headers: headers }
      );
    } else {
      return this.httpClient.post(
        `${environment.apiUrl}/admin/completeBooking`,
        { id: obj },
        { headers: headers }
      );
    }
  }

  redeemVoucherPrice(obj) {
    let headers = new HttpHeaders({
      Authorization: "Bearer " + JSON.parse(localStorage.getItem("user")).token,
    });
    if (this.role == "admin") {
      return this.httpClient.post(
        `${environment.apiUrl}/admin/redeemedVoucher`,
        { data: obj },
        { headers: headers }
      );
    } else {
      return this.httpClient.post(
        `${environment.apiUrl}/admin/redeemedVoucher`,
        { data: obj },
        { headers: headers }
      );
    }
  }

  redeemMembershipPrice(obj) {
    let headers = new HttpHeaders({
      Authorization: "Bearer " + JSON.parse(localStorage.getItem("user")).token,
    });
    if (this.role == "admin") {
      return this.httpClient.post(
        `${environment.apiUrl}/admin/redeemedMemberVoucher`,
        { data: obj },
        { headers: headers }
      );
    } else {
      return this.httpClient.post(
        `${environment.apiUrl}/admin/redeemedMemberVoucher`,
        { data: obj },
        { headers: headers }
      );
    }
  }

  updatePayment(obj, dt) {
    let headers = new HttpHeaders({
      Authorization: "Bearer " + JSON.parse(localStorage.getItem("user")).token,
    });
    if (this.role == "admin") {
      return this.httpClient.post(
        `${environment.partnerapiUrl}/admin/updatePayment`,
        { data: obj, apponintmentData: dt },
        { headers: headers }
      );
    } else {
      return this.httpClient.post(
        `${environment.apiUrl}/admin/updatePayment`,
        { data: obj, apponintmentData: dt },
        { headers: headers }
      );
    }
  }

  updatePaymentForVoucher(obj, dt) {
    let headers = new HttpHeaders({
      Authorization: "Bearer " + JSON.parse(localStorage.getItem("user")).token,
    });
    if (this.role == "admin") {
      return this.httpClient.post(
        `${environment.partnerapiUrl}/admin/updatePaymentVoucher`,
        { data: obj, apponintmentData: dt },
        { headers: headers }
      );
    } else {
      return this.httpClient.post(
        `${environment.partnerapiUrl}/admin/updatePaymentVoucher`,
        { data: obj, apponintmentData: dt },
        { headers: headers }
      );
    }
  }

  updateCompletePayment(obj, dt) {
    let headers = new HttpHeaders({
      Authorization: "Bearer " + JSON.parse(localStorage.getItem("user")).token,
    });
    if (this.role == "admin") {
      return this.httpClient.post(
        `${environment.apiUrl}/admin/updateCompletePayment`,
        { data: obj, apponintmentData: dt },
        { headers: headers }
      );
    } else {
      return this.httpClient.post(
        `${environment.apiUrl}/admin/updateCompletePayment`,
        { data: obj, apponintmentData: dt },
        { headers: headers }
      );
    }
  }

  changeMessage(message: string) {
    this.messageSource.next(message);
  }

  private newUser = new BehaviorSubject<any>({});
  // Setting Data To Subject
  setNewUserInfo(user: any) {
    this.newUser.next(user);
  }
  // Getting Data As Observable And Subscribe On Main Page.
  getNewUserInfo() {
    return this.newUser.asObservable();
  }

  getBookedData() {
    let headers = new HttpHeaders({
      Authorization: "Bearer " + JSON.parse(localStorage.getItem("user")).token,
    });
    if (this.role == "admin") {
      return this.httpClient.post(
        `${environment.apiUrl}/admin/getAllBookings`,
        {},
        { headers: headers }
      );
    } else if (this.role == "partner") {
      return this.httpClient.post(
        `${environment.partnerapiUrl}/partner/getPartnerBookings`,
        { id: this.user_data._id },
        { headers: headers }
      );
    } else {
      return this.httpClient.post(
        `${environment.apiUrl}/admin/getAllBookings`,
        {},
        { headers: headers }
      );
    }
  }

  getBookedDataWithDate(date) {
    let headers = new HttpHeaders({
      Authorization: "Bearer " + JSON.parse(localStorage.getItem("user")).token,
    });
    if (this.role == "admin") {
      return this.httpClient.post(
        `${environment.apiUrl}/admin/getAllBookingsNew`,
        { dt: date },
        { headers: headers }
      );
    } else if (this.role == "partner") {
      return this.httpClient.post(
        `${environment.partnerapiUrl}/partner/getPartnerBookingsNew`,
        { id: this.user_data._id, dt: date },
        { headers: headers }
      );
    } else {
      return this.httpClient.post(
        `${environment.apiUrl}/admin/getAllBookingsNew`,
        { dt: date },
        { headers: headers }
      );
    }
  }

  getBookedDataById(_id) {
    let headers = new HttpHeaders({
      Authorization: "Bearer " + JSON.parse(localStorage.getItem("user")).token,
    });
    if (this.role == "admin") {
      return this.httpClient.post(
        `${environment.apiUrl}/admin/getBookingById`,
        { id: _id },
        { headers: headers }
      );
    } else {
      return this.httpClient.post(
        `${environment.apiUrl}/admin/getBookingById`,
        { id: _id },
        { headers: headers }
      );
    }
  }

  getUserData(obj) {
    const headers = new HttpHeaders({
      Authorization: "Bearer " + JSON.parse(localStorage.getItem("user")).token,
    });
    if (this.user_data.role == "patient") {
      return this.httpClient.post(
        `${environment.apiUrl}/user/searchPatientByMobile`,
        { mobile: obj.mobile },
        { headers: headers }
      );
    } else if (this.user_data.role == "admin") {
      return this.httpClient.post(
        `${environment.apiUrl}/partner/searchClientByMobile`,
        { mobile: obj.mobile },
        { headers: headers }
      );
    } else if (this.user_data.role == "partner") {
      return this.httpClient.post(
        `${environment.partnerapiUrl}/partner/searchClientByMobile`,
        { mobile: obj.mobile },
        { headers: headers }
      );
    } else {
      return this.httpClient.post(
        `${environment.apiUrl}/partner/searchClientByMobile`,
        { mobile: obj.mobile },
        { headers: headers }
      );
    }
  }

  getPackage(v) {
    let headers = new HttpHeaders({
      Authorization: "Bearer " + JSON.parse(localStorage.getItem("user")).token,
    });

    if (this.user_data.role == "admin") {
      return this.httpClient.post(
        `${environment.apiUrl}/admin/getAllPackagesByPartnerId`,
        { id: v },
        { headers: headers }
      );
    } else if (this.user_data.role == "partner") {
      return this.httpClient.post(
        `${environment.partnerapiUrl}/partner/getAllPackagesByPartnerId`,
        { id: v },
        { headers: headers }
      );
    } else {
      return this.httpClient.post(
        `${environment.apiUrl}/admin/getAllPackagesByPartnerId`,
        { id: v },
        { headers: headers }
      );
    }
  }

  getPackagesIfPartnerOfferExists(v, id) {
    let headers = new HttpHeaders({
      Authorization: "Bearer " + JSON.parse(localStorage.getItem("user")).token,
    });

    if (this.user_data.role == "admin") {
      return this.httpClient.post(
        `${environment.apiUrl}/partner/getPackagesWithPackageOffer`,
        { id: v, offerId: id },
        { headers: headers }
      );
    } else if (this.user_data.role == "partner") {
      return this.httpClient.post(
        `${environment.partnerapiUrl}/partner/getPackagesWithPackageOffer`,
        { id: v, offerId: id },
        { headers: headers }
      );
    } else {
      return this.httpClient.post(
        `${environment.apiUrl}/partner/getPackagesWithPackageOffer`,
        { id: v, offerId: id },
        { headers: headers }
      );
    }
  }

  getPackagesIfPartnerOfferExistsForAllTypePackages(v, id) {
    let headers = new HttpHeaders({
      Authorization: "Bearer " + JSON.parse(localStorage.getItem("user")).token,
    });

    if (this.user_data.role == "admin") {
      return this.httpClient.post(
        `${environment.apiUrl}/partner/getPackagesWithAllPackageOffer`,
        { id: v, offerId: id },
        { headers: headers }
      );
    } else if (this.user_data.role == "partner") {
      return this.httpClient.post(
        `${environment.partnerapiUrl}/partner/getPackagesWithAllPackageOffer`,
        { id: v, offerId: id },
        { headers: headers }
      );
    } else {
      return this.httpClient.post(
        `${environment.apiUrl}/partner/getPackagesWithAllPackageOffer`,
        { id: v, offerId: id },
        { headers: headers }
      );
    }
  }

  // booking Status Api
  getBookingStatus() {
    let headers = new HttpHeaders({
      Authorization: "Bearer " + JSON.parse(localStorage.getItem("user")).token,
    });
    return this.httpClient.post(
      `${environment.apiUrl}/partner/getAllBookingStatus`,
      {},
      { headers: headers }
    );
  }

  getAllStaffsForPartner(obj) {
    let headers = new HttpHeaders({
      Authorization: "Bearer " + JSON.parse(localStorage.getItem("user")).token,
    });
    const user = JSON.parse(localStorage.getItem("user"));
    return this.httpClient.post<any>(
      `${environment.apiUrl}/partner/getPartnerStaff`,
      obj,
      { headers: headers }
    );
  }

  getAllStaffsForPartnerNew(obj) {
    let headers = new HttpHeaders({
      Authorization: "Bearer " + JSON.parse(localStorage.getItem("user")).token,
      //'Content-type': 'multipart/form-data'
    });
    const user = JSON.parse(localStorage.getItem("user"));
    return this.httpClient.post<any>(
      `${environment.apiUrl}/partner/getPartnerDataId`,
      obj,
      { headers: headers }
    );
  }

  saveEvents(obj) {
    let headers = new HttpHeaders({
      Authorization: "Bearer " + JSON.parse(localStorage.getItem("user")).token,
      //'Content-type': 'multipart/form-data'
    });

    const user = JSON.parse(localStorage.getItem("user"));
    return this.httpClient.post<any>(
      `${environment.partnerapiUrl}/admin/addAppointmentEvent`,
      { data: obj },
      { headers: headers }
    );
  }

  updateEventArr(obj) {
    const user = JSON.parse(localStorage.getItem("user"));
    let headers = new HttpHeaders({
      Authorization: "Bearer " + JSON.parse(localStorage.getItem("user")).token,
    });
    return this.httpClient.post<any>(`${environment.partnerapiUrl}/partner/updateAppointmentEvent`, { data: obj }, { headers: headers });
  }

  sellVoucher(obj) {
    let headers = new HttpHeaders({
      Authorization: "Bearer " + JSON.parse(localStorage.getItem("user")).token,
      //'Content-type': 'multipart/form-data'
    });

    const user = JSON.parse(localStorage.getItem("user"));
    return this.httpClient.post<any>(
      `${environment.apiUrl}/partner/addSellVoucher`,
      { data: obj },
      { headers: headers }
    );
  }

  getClientByID(id) {
    let headers = new HttpHeaders({
      Authorization: "Bearer " + JSON.parse(localStorage.getItem("user")).token,
      //'Content-type': 'multipart/form-data'
    });

    if (this.role == "admin") {
      return this.httpClient.post(
        `${environment.apiUrl}/admin/getClientById`,
        { id: id },
        { headers: headers }
      );
    } else if (this.role == "partner") {
      return this.httpClient.post(
        `${environment.partnerapiUrl}/partner/getClientById`,
        { id: id },
        { headers: headers }
      );
    } else {
      return this.httpClient.post(
        `${environment.apiUrl}/admin/getClientById`,
        { id: id },
        { headers: headers }
      );
    }
  }

  getPartnerVoucher(id) {
    let headers = new HttpHeaders({
      Authorization: "Bearer " + JSON.parse(localStorage.getItem("user")).token,
      //'Content-type': 'multipart/form-data'
    });
    return this.httpClient.post(
      `${environment.apiUrl}/partner/getVoucherForSell`,
      { id: id },
      { headers: headers }
    );
  }

  getPartnerProduct(id) {
    let headers = new HttpHeaders({
      Authorization: "Bearer " + JSON.parse(localStorage.getItem("user")).token,
      //'Content-type': 'multipart/form-data'
    });

    return this.httpClient.post(
      `${environment.apiUrl}/admin/getSupplierProductByPartnerId`,
      { id: id },
      { headers: headers }
    );
  }

  // When We Sell Product During Sell.
  sellProduct(obj) {
    let headers = new HttpHeaders({
      Authorization: "Bearer " + JSON.parse(localStorage.getItem("user")).token,
      //'Content-type': 'multipart/form-data'
    });
    const user = JSON.parse(localStorage.getItem("user"));
    return this.httpClient.post<any>(
      `${environment.apiUrl}/partner/addSellProduct`,
      { data: obj },
      { headers: headers }
    );
  }

  paymentLink(obj) {
    let headers = new HttpHeaders({
      Authorization: "Bearer " + JSON.parse(localStorage.getItem("user")).token,
      //'Content-type': 'multipart/form-data'
    });
    return this.httpClient.post<any>(
      `${environment.apiUrl}/partner/sendPaymentLinkEmail`,
      obj,
      { headers: headers }
    );
  }

  updatePaymentForProduct(obj, dt) {
    let headers = new HttpHeaders({
      Authorization: "Bearer " + JSON.parse(localStorage.getItem("user")).token,
    });
    if (this.role == "admin") {
      return this.httpClient.post(
        `${environment.apiUrl}/partner/updatePaymentProduct`,
        { data: obj, apponintmentData: dt },
        { headers: headers }
      );
    } else {
      return this.httpClient.post(
        `${environment.apiUrl}/partner/updatePaymentProduct`,
        { data: obj, apponintmentData: dt },
        { headers: headers }
      );
    }
  }

  saveTip(obj) {
    let headers = new HttpHeaders({
      Authorization: "Bearer " + JSON.parse(localStorage.getItem("user")).token,
    });
    if (this.role == "admin") {
      return this.httpClient.post(
        `${environment.apiUrl}/partner/updateAppointmentTip`,
        obj,
        { headers: headers }
      );
    } else {
      return this.httpClient.post(
        `${environment.apiUrl}/partner/updateAppointmentTip`,
        obj,
        { headers: headers }
      );
    }
  }

  //View Membership Card//
  getPartnerMembership(id) {
    let headers = new HttpHeaders({
      Authorization: "Bearer " + JSON.parse(localStorage.getItem("user")).token,
      //'Content-type': 'multipart/form-data'
    });

    return this.httpClient.post(
      `${environment.apiUrl}/partner/getAllMemberVoucher`,
      { id: id },
      { headers: headers }
    );
  }

  sellMembership(obj) {
    let headers = new HttpHeaders({
      Authorization: "Bearer " + JSON.parse(localStorage.getItem("user")).token,
    });
    const user = JSON.parse(localStorage.getItem("user"));
    return this.httpClient.post(
      `${environment.apiUrl}/partner/addSellMemberVoucher`,
      { data: obj },
      { headers: headers }
    );
  }

  updatePaymentForMember(obj, dt) {
    let headers = new HttpHeaders({
      Authorization: "Bearer " + JSON.parse(localStorage.getItem("user")).token,
    });
    return this.httpClient.post(
      `${environment.apiUrl}/partner/updatePaymentMemberVoucher`,
      { data: obj, apponintmentData: dt },
      { headers: headers }
    );
  }

  // When We Add More Services And Packages From Add More Button Click
  updateEventArrayWhenAddMore(obj) {
    let headers = new HttpHeaders({
      Authorization: "Bearer " + JSON.parse(localStorage.getItem("user")).token,
    });
    return this.httpClient.post(
      `${environment.apiUrl}/partner/addMoreServices`,
      { data: obj },
      { headers: headers }
    );
  }

  // If We Clicking On The completed Not Payed Button Hit This Service.
  completedNotPayed(obj) {
    let headers = new HttpHeaders({
      Authorization: "Bearer " + JSON.parse(localStorage.getItem("user")).token,
    });
    return this.httpClient.post<any>(
      `${environment.apiUrl}/admin/updateEventForMember`,
      { id: obj.id, remainingAmt: obj.remainingAmt, totalCost: obj.totalCost },
      { headers: headers }
    );
  }

  // Before API Using.
  // getlookupByType(type) {
  //   let headers = new HttpHeaders({
  //     'Authorization': 'Bearer ' + (JSON.parse(localStorage.getItem('user'))).token,
  //   })
  //   return this.httpClient
  //     .post(`${environment.apiUrl}/api/getlookupByType`, { type: type }, { headers: headers });
  // }

  // After API Using.
  getlookupByType(type) {
    let headers = new HttpHeaders({
      Authorization: "Bearer " + JSON.parse(localStorage.getItem("user")).token,
    });
    return this.httpClient.post(
      `${environment.apiUrl}/app/getPaymentMethodApp`,
      { type: type },
      { headers: headers }
    );
  }

  getAllClient(dt) {
    let headers = new HttpHeaders({
      Authorization: "Bearer " + JSON.parse(localStorage.getItem("user")).token,
    });
    return this.httpClient.post(
      `${environment.apiUrl}/partner/getClientPartnerNew`,
      { id: dt },
      { headers: headers }
    );
  }

  getAllSubServices(obj) {
    let headers = new HttpHeaders({
      Authorization: "Bearer " + JSON.parse(localStorage.getItem("user")).token,
    });
    return this.httpClient.post(
      `${environment.partnerapiUrl}/partner/getSubServicesName`,
      { offerId: obj.offerId },
      { headers: headers }
    );
  }

  updatePaymentArrayDuringOnlinePayment(obj) {
    let headers = new HttpHeaders({
      Authorization: "Bearer " + JSON.parse(localStorage.getItem("user")).token,
    });
    return this.httpClient.post(
      `${environment.apiUrl}/admin/updatePaymentArrOnline`,
      { data: obj },
      { headers: headers }
    );
  }

  // Here Is Getting Voucher Data Based On PartnerID.
  getVoucherData(id) {
    let headers = new HttpHeaders({
      Authorization: "Bearer " + JSON.parse(localStorage.getItem("user")).token,
    });
    return this.httpClient.post(
      `${environment.apiUrl}/partner/getVoucherDetailsById`,
      { id: id },
      { headers: headers }
    );
  }

  // Here Is Getting Plan Data Based On Plan ID.
  getMemberVoucherData(id) {
    let headers = new HttpHeaders({
      Authorization: "Bearer " + JSON.parse(localStorage.getItem("user")).token,
    });
    return this.httpClient.post(
      `${environment.apiUrl}/partner/getMemberVoucherById`,
      { id: id },
      { headers: headers }
    );
  }

  // Here Is Getting Voucher Data Based On Client ID/PartnerId And Type.
  getVouchersDataForCheckoutPage(obj) {
    let headers = new HttpHeaders({
      Authorization: "Bearer " + JSON.parse(localStorage.getItem("user")).token,
    });
    return this.httpClient.post(
      `${environment.apiUrl}/partner/getClientVouchersByPartnerId`,
      {
        clientId: obj.clientId,
        partnerId: obj.partnerId,
        voucherType: obj.voucherType,
        voucherCode: obj.voucherCode,
      },
      { headers: headers }
    );
  }

  // This Is For Getting Invoice Data.
  getPartnerInvoiceById(id) {
    let headers = new HttpHeaders({
      Authorization: "Bearer " + JSON.parse(localStorage.getItem("user")).token,
    });

    if (this.role === "admin") {
      return this.httpClient.post(`${environment.partnerapiUrl}/partner/getPartnerInvoiceById`, { id: id }, { headers: headers });
    } else {
      return this.httpClient.post(`${environment.partnerapiUrl}/partner/getPartnerInvoiceById`, { id: id }, { headers: headers });
    }

    // return this.httpClient.post(
    //   `${environment.partnerapiUrl}/partner/getPartnerInvoiceById`,
    //   { id: id },
    //   { headers: headers }
    // );
  }

  // Here Is Getting Membership Data Based On Client ID/PartnerId And Type.
  getMembershipDataForCheckoutPage(obj) {
    let headers = new HttpHeaders({
      Authorization: "Bearer " + JSON.parse(localStorage.getItem("user")).token,
    });
    return this.httpClient.post(
      `${environment.apiUrl}/partner/getSellMembershipBasedOnPartner`,
      {
        clientId: obj.clientId,
        partnerId: obj.partnerId,
        voucherType: obj.voucherType,
        voucherCode: obj.voucherCode,
      },
      { headers: headers }
    );
  }

  // get offer data api
  getAppliedOfferData(offerId) {
    let headers = new HttpHeaders({
      Authorization: "Bearer " + JSON.parse(localStorage.getItem("user")).token,
    });
    return this.httpClient.post(
      `${environment.partnerapiUrl}/partner/getOfferDetailsForPartner`,
      { offerId: offerId },
      { headers: headers }
    );
  }

  getBookingServiceType() {
    let headers = new HttpHeaders({
      Authorization: "Bearer " + JSON.parse(localStorage.getItem("user")).token,
    });
    return this.httpClient.post(`${environment.partnerapiUrl}/partner/getMasterBookingType`, {}, { headers: headers });
  }

  // get partner All offer data api
  getPartnerAllOffer(partnerId) {
    let headers = new HttpHeaders({
      Authorization: "Bearer " + JSON.parse(localStorage.getItem("user")).token,
    });
    return this.httpClient.post(
      `${environment.partnerapiUrl}/partner/getAllPartnersOffer`,
      { partnerId: partnerId },
      { headers: headers }
    );
  }

  // It Is Used To Get Data Invoice Data.
  // getInvoice(_id: string) {
  //   const headers = new HttpHeaders({
  //     Authorization: "Bearer " + JSON.parse(localStorage.getItem("user")).token,
  //   });

  //   if (this.role === "admin") {
  //     return this.httpClient.post(
  //       `${environment.partnerapiUrl}/partner/getInvoiceByBookingId`,
  //       { id: _id },
  //       { headers: headers }
  //     );
  //   } else {
  //     return this.httpClient.post(
  //       `${environment.partnerapiUrl}/partner/getInvoiceByBookingId`,
  //       { id: _id },
  //       { headers: headers }
  //     );
  //   }
  // }

  commonForNotification(param) {
    let obj = {
      title: `${param.title}`,
      body: `${param.body}`,
      image: `${param.image}`,
    };
    this.notfication.showNotification({ notification: obj });
  }
}
